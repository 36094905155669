import { blue } from '@mui/material/colors';
import React,{useState} from 'react';

const SousCard = ({product}) => {
    const [afficherSousComposant, setAfficherSousComposant] = useState(true);
    return (
        <div >
            {afficherSousComposant &&    
            <div className="product-details-popup-wrapper popup "style={{ zIndex:5 ,backgroundColor: 'rgba(0, 0, 0, 0.5)'}}> 
            <div className="rts-product-details-section rts-product-details-section2 product-details-popup-section">
                <div className="product-details-popup">
                    <button className="product-details-close-btn"onClick={() => setAfficherSousComposant(false)}><i className="fal fa-times" /></button>
                    <div className="details-product-area">
                        <div className="product-thumb-area">
                            <div className="cursor" />
                            <div className="thumb-wrapper one filterd-items figure">
                                <div className="product-thumb zoom" onmousemove="zoom(event)" style={{ backgroundImage:'url( ${product.image})' }}><img src={product.image} alt="product-thumb" />
                                </div>
                            </div>
                            <div className="thumb-wrapper two filterd-items hide">
                                <div className="product-thumb zoom" onmousemove="zoom(event)" style={{ backgroundImage: 'url(${product.image})' }}><img src={product.image} alt="product-thumb" />
                                </div>
                            </div>
                            <div className="thumb-wrapper three filterd-items hide">
                                <div className="product-thumb zoom" onmousemove="zoom(event)" style={{ backgroundImage: 'url(${product.image})' }}><img src={product.image} alt="product-thumb" />
                                </div>
                            </div>
                            <div className="product-thumb-filter-group">
                                <div className="thumb-filter filter-btn active" data-show=".one"><img src="assets/images/products/product-filt1.jpg" alt="product-thumb-filter" /></div>
                                <div className="thumb-filter filter-btn" data-show=".two"><img src="assets/images/products/product-filt2.jpg" alt="product-thumb-filter" /></div>
                                <div className="thumb-filter filter-btn" data-show=".three"><img src="assets/images/products/product-filt3.jpg" alt="product-thumb-filter" /></div>
                            </div>
                        </div>
                        <div className="contents">
                            <div className="product-status">
                                <span className="product-catagory">Dress</span>
                                <div className="rating-stars-group">
                                    <div className="rating-star"><i className="fas fa-star" /></div>
                                    <div className="rating-star"><i className="fas fa-star" /></div>
                                    <div className="rating-star"><i className="fas fa-star-half-alt" /></div>
                                    <span>10 Reviews</span>
                                </div>
                            </div>
                            <h2 className="product-title">{product.name} <span className="stock">In Stock</span></h2>
                            <span className="product-price"><span className="old-price">${product.price}</span> ${product.price}</span>
                            <p>
                            {product.description}
                            </p>
                            <div className="product-bottom-action">
                                <div className="cart-edit">
                                    <div className="quantity-edit action-item">
                                        <button className="button"><i className="fal fa-minus minus" /></button>
                                        <input type="text" className="input"  />
                                        <button className="button plus">+<i className="fal fa-plus plus" /></button>
                                    </div>
                                </div>
                                <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                    <div className="btn-text">
                                        Add To Cart
                                    </div>
                                    <div className="arrow-icon">
                                        <i className="fa-regular fa-cart-shopping" />
                                    </div>
                                    <div className="arrow-icon">
                                        <i className="fa-regular fa-cart-shopping" />
                                    </div>
                                </a>
                                <a href="javascript:void(0);" className="rts-btn btn-primary ml--20"><i className="fa-light fa-heart" /></a>
                            </div>
                            <div className="product-uniques">
                                <span className="sku product-unipue"><span>SKU: </span> BO1D0MX8SJ</span>
                                <span className="catagorys product-unipue"><span>Categories: </span> T-Shirts, Tops, Mens</span>
                                <span className="tags product-unipue"><span>Tags: </span> fashion, t-shirts, Men</span>
                            </div>
                            <div className="share-social">
                                <span>Share:</span>
                                <a className="platform" href="http://facebook.com" target="_blank"><i className="fab fa-facebook-f" /></a>
                                <a className="platform" href="http://twitter.com" target="_blank"><i className="fab fa-twitter" /></a>
                                <a className="platform" href="http://behance.com" target="_blank"><i className="fab fa-behance" /></a>
                                <a className="platform" href="http://youtube.com" target="_blank"><i className="fab fa-youtube" /></a>
                                <a className="platform" href="http://linkedin.com" target="_blank"><i className="fab fa-linkedin" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        </div>



    );
};

export default SousCard;