import { combineReducers } from 'redux';
// import __Ct from './Cstate';

import __iA from './Auth';
import __ct from './cardReducer';
import __cw from './wishlistReducer';

// const RootReducer = combineReducers({__iA,__Ct,__pt});
const RootReducer = combineReducers({__iA,__ct,__cw});

export default RootReducer;