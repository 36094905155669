import React, { useState, useEffect } from 'react'; 
import { useDispatch } from 'react-redux';
import ProductService from '../../services/product';
import categoryServices from '../../services/categories';

const SearchBarre = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [Products, setAllProducts] = useState([]); // Liste des produits
  const [categories, setCategories] = useState([]); // Liste des catégories
  const [filteredResults, setFilteredResults] = useState([]); // Résultats filtrés
  const dispatch = useDispatch();

  // Fonction pour récupérer tous les produits depuis l'API
  const allProducts = () => {
    dispatch(ProductService.getAllProducts())
      .then((result) => {
        let res = result.data;
        setAllProducts(res); // Mettre à jour la liste des produits
      })
      .catch((e) => {
        // console.log('errors ', e);
      });
  };

  // Fonction pour récupérer toutes les catégories
  const allCategories = () => {
    dispatch(categoryServices.getAllCategories())
        .then((result) => {
            let res = result.data;
            setCategories(res); // Mettre à jour la liste des catégories
        })
        .catch((e) => {
            console.log('errors ', e);
        });
  }

  // Effet pour récupérer la liste des produits et des catégories au démarrage
  useEffect(() => {
    allProducts();
    allCategories();
  }, []);

  // Effet pour filtrer les produits et les catégories en fonction du terme de recherche
  useEffect(() => {
    if (searchTerm.length >= 3) {
      // Filtrer les produits en fonction du terme de recherche
      const productResults = Products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Filtrer les catégories en fonction du terme de recherche
      const categoryResults = categories.filter((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Combiner les résultats des produits et des catégories
      const results = [...productResults, ...categoryResults];
      setFilteredResults(results);
    } else {
      setFilteredResults([]); // Si le terme de recherche est inférieur à 3 caractères, on efface les résultats
    }
  }, [searchTerm, Products, categories]);

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value); // Met à jour le terme de recherche
  };

  return (
    <div>
      <form action="#" className="search-header">
        <input 
          type="text" 
          placeholder="Search for products, categories" 
          value={searchTerm}
          onChange={handleSearchInput} 
          required 
        />
        <a href="javascript: void(0);" className="rts-btn btn-primary radious-sm with-icon">
          <div className="btn-text">Search</div>
          <div className="arrow-icon">
            <i className="fa-light fa-magnifying-glass" />
          </div>
        </a>
      </form>

      {searchTerm.length >= 3 && (
        <ul>
          {filteredResults.length === 0 ? (
            <p>No results found</p>
          ) : (
            filteredResults.map((result) => (
              <li key={result.id}>
                <div>
                  {/* Si c'est un produit */}
                  {result.name && result.image ? (
                    <>
                      <img 
                        src={result.image} 
                        alt={result.name} 
                        style={{ width: '50px', height: '50px', marginRight: '10px' }} 
                      />
                      <span>{result.name}</span>
                    </>
                  ) : (
                    // Si c'est une catégorie, afficher l'image de la catégorie
                    result.images && result.images.length > 0 && result.name ? (
                      <>
                        <img 
                          src={result.images[0]} 
                          alt={result.name} 
                          style={{ width: '50px', height: '50px', marginRight: '10px' }} 
                        />
                        <span>{result.name}</span>
                      </>
                    ) : (
                      // Si c'est une catégorie sans image
                      <span>{result.name}</span>
                    )
                  )}
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBarre;
