import React from 'react';
import { Link } from 'react-router-dom';


const Pricecing2 = () => {
    return (

        <div style={{fontFamily:"Poppins"}}>
            <div className="container" >

               {/*  <div className="offer-section offer-section1 about-offer-section" data-aos="fade-up" style={{ paddingBottom: 150 }}  >
                    <h2 className="offer-heading">Nos  <span className="support">prestations</span></h2>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img2.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>Scanner</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img2.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>specialiste</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img3.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>generaliste</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img3.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>Our Mission</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
 */}












                <h2 className="pt-5">Choisissez votre plan tarifaire abordable</h2>

                <div className="plan-con">
                    <div className="row">
                        <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                            <div className="plan-item text-center">
                                <div className="plan-header bg-success">
                                    <span className="d-block">Assistance menagere(basique)</span>
                                    <div className="plan-price">$25.00</div>
                                    <div className="paln-tag">Par an</div>
                                </div>
                                <div className="plan-body">
                                    <span className="d-block">We are committed to give our
                                        best services</span>
                                    <ul className="list-unstyled">
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Bedrroms
                                            Cleaning</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming
                                        </li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 2
                                            Bathroom Cleaning</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window
                                            Cleaning</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 1
                                            Livingroom Cleaning</li>
                                    </ul>
                                    <div className="plan-btn">
                                        <Link to={"/login"}>choisi ton plan </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                           
                        </div>
                        <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                            <div className="plan-item text-center">
                                <div className="plan-header bg-success">
                                    <span className="d-block">Entretien maintenance(Prenium)</span>
                                    <div className="plan-price">$25.00</div>
                                    <div className="paln-tag">Par an</div>
                                </div>
                                <div className="plan-body">
                                    <span className="d-block">We are committed to give our
                                        best services</span>
                                    <ul className="list-unstyled">
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4Bedrroms Cleaning</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Bathroom Cleaning</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window Cleaning</li>
                                        <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Livingroom Cleaning</li>
                                    </ul>
                                    <div className="plan-btn">
                                        <Link to={"/login"}>choisi ton plan </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Pricecing2;