import React, { useState } from 'react';
import "./personnalise.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const Personnalise = () => {

    const articles = [
        { title: 'Article 1', price: 100 },
        { title: 'Article 2', price: 200 },
        { title: 'Article 3', price: 300 },
    ];


    // Fonction pour basculer l'état de visibilité
    const [isVisible, setIsVisible] = useState(false);   // Première div (masquée au départ)
    const [isVisible_, setIsVisible_] = useState(false); // Deuxième div (masquée au départ)
    const [isVisible__, setIsVisible__] = useState(false);

    // Fonction pour afficher la première div et masquer la deuxième div
    const showFirstDiv = () => {
        setIsVisible(true);  // Afficher la première div
        setIsVisible_(false);
        setIsVisible__(false); // Masquer la deuxième div
    };

    // Fonction pour afficher la deuxième div et masquer la première div
    const showSecondDiv = () => {
        setIsVisible(false);  // Masquer la première div
        setIsVisible_(true);   // Afficher la deuxième div
        setIsVisible__(false);
    };
    const showthirDiv = () => {
        setIsVisible(false);  // Masquer la première div
        setIsVisible_(false);   // Afficher la deuxième div
        setIsVisible__(true);
    }



    return (


        <div style={{  paddingTop: 200 }}>

            <div className="choose-us-section offer-section offer-section1" data-aos="fade-up">
                <div className="container-fluid">
                    <div className="row ">

                        <div className="chooseus-content position-relative">
                            <h2 style={{ paddingTop: 10 }} className="why-chooseus">vos packs de prise en charge </h2>
                            <div className="chooseus-content-box" data-aos="fade-up" onClick={showFirstDiv} style={{ width: 300, height: 225, margin: 10 }}>
                                <figure className="float-left mb-0 pt-2">
                                    <img className="img-fluid" src="./assets/images/why-chooseus-icon1.png" alt />
                                </figure>
                                <div className="chooseus-content-box-content float-left">
                                    <p style={{ padding: 5 }} className="chooseus-title">Pack santé</p>
                                    {/*  <p className="chooseus-p">Quis autem vel eum iure reprehenderit qui in eareu voluptate velit esse quam nihil molestiae.</p> */}
                                </div>
                            </div>
                            <div className="chooseus-content-box" data-aos="fade-up" onClick={showSecondDiv} style={{ width: 300, height: 225, margin: 10 }}>
                                <figure className="float-left mb-0 pt-2">
                                    <img className="img-fluid" src="./assets/images/why-chooseus-icon2.png" alt />
                                </figure>
                                <div className="chooseus-content-box-content float-left">
                                    <p style={{ padding: 5 }} className="chooseus-title">Pack gestion du domicile </p>
                                    {/* <p className="chooseus-p">Quis autem vel eum iure reprehenderit qui in eareu voluptate velit esse quam nihil molestiae.</p> */}
                                </div>
                            </div>
                            <div className="chooseus-content-box" data-aos="fade-up" onClick={showthirDiv} style={{ width: 300, height: 225, margin: 10 }}>
                                <figure className="float-left mb-0 pt-2">
                                    <img className="img-fluid" src="./assets/images/why-chooseus-icon3.png" alt />
                                </figure>
                                <div className="chooseus-content-box-content float-left">
                                    <p style={{ padding: 5 }} className="chooseus-title">Pack besoin informatique</p>
                                    {/*  <p className="chooseus-p">Quis autem vel eum iure reprehenderit qui in eareu voluptate velit esse quam nihil molestiae.</p> */}
                                </div>
                            </div>
                        </div>

                        <div style={{ Width: 700 }}>
                            <div style={{textAlign:"center"}}><h4 className="why-chooseus" style={{ paddingTop: 25, paddingLeft: 15, color: "#4CAF50" }}>Vos offres choisies </h4></div>
                            <div className="container mt-5">
                                <div className="container mt-5">
                                    <table className="table table-striped table-bordered border-1" style={{ paddingLeft: 20 }} >
                                        <thead className="bg-success text-white">
                                            <tr>
                                                <th scope="col" style={{ width: '50%' }}>Titre de l'article</th>
                                                <th scope="col">Prix</th>
                                                <th scope="col" style={{ width: '15%' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {articles.map((article, index) => (
                                                <tr key={index} className="table-success">
                                                    <td>{article.title}</td>
                                                    <td>{article.price} FCFA</td>
                                                    <td>
                                                        <button className="btn btn-danger">
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2" className="text-right"><strong>Total</strong></td>
                                                <td>10000 FCFA</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    <Link to={"/login"} className="btn btn-success">Continuer</Link>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            {isVisible &&

                <div className="container-fluid mt-5 "  >
                    <h4 style={{ color: "#4CAF50", padding: 20 }} className="pt-5">Choisissez votre plan tarifaire abordable</h4>
                    <div className="plan-con">
                        <div className="row">
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Consultation(basique)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Bedrroms
                                                Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming
                                            </li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 2
                                                Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window
                                                Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 1
                                                Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Prevention(medium)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 3Bedrroms Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 3 Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 3 Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn s">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Complet(Prenium)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4Bedrroms Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
            {isVisible_ &&
                <div className="container-fluid mt-5 "  >
                    <h4 style={{ color: "#4CAF50", padding: 20 }} className="pt-5">Choisissez votre plan tarifaire abordable</h4>
                    <div className="plan-con">
                        <div className="row">
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Assistance menagere(basique)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Bedrroms
                                                Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming
                                            </li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 2
                                                Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window
                                                Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 1
                                                Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>

                            </div>
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Entretien maintenance(Prenium)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4Bedrroms Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isVisible__ &&
                <div className="container-fluid mt-5 "  >
                    <h4 style={{ color: "#4CAF50", padding: 20 }} className="pt-5">Choisissez votre plan tarifaire abordable</h4>
                    <div className="plan-con">
                        <div className="row">
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Formation numerique (basique)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Bedrroms
                                                Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming
                                            </li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 2
                                                Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window
                                                Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 1
                                                Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Assistance technique(medium)</span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 3Bedrroms Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 3 Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 3 Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn s">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-duration={600}>
                                <div className="plan-item text-center">
                                    <div className="plan-header bg-success">
                                        <span className="d-block">Support technique avancee  </span>
                                        <div className="plan-price">$25.00</div>
                                        <div className="paln-tag">Par an</div>
                                    </div>
                                    <div className="plan-body">
                                        <span className="d-block">We are committed to give our
                                            best services</span>
                                        <ul className="list-unstyled">
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4Bedrroms Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Vacuming</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Bathroom Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> Window Cleaning</li>
                                            <li className="position-relative"><img src="assets/images/plan-check.png" alt="plan-check" /> 4 Livingroom Cleaning</li>
                                        </ul>
                                        <div className="plan-btn">
                                            <a href='#'>Ajouter au panier  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>

    );
};

export default Personnalise;