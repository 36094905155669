import Http from '../../Http'
import * as action from '../../store/actions'


export function getAllCategories() {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`https://api.wemaza.com/v1/categories/featured`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};
export function getCategories() {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`https://api.wemaza.com/v1/categories?limit=100`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getCategoryById(id) {
    const limit = 250 
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/categories/${id}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

const BASE_URL = 'https://api.wemaza.com/v1/products-slug/Cameroun';

export const getProducts = async (categorySlug, subcategorySlug, subsubcategorySlug) => {
    let url = `${BASE_URL}`;

    // Ajouter les paramètres de catégorie si fournis
    if (categorySlug) url += `/${categorySlug}`;
    if (subcategorySlug) url += `/${subcategorySlug}`;
    if (subsubcategorySlug) url += `/${subsubcategorySlug}`;

    // Inclure les relations supplémentaires dans la requête
    url += '?include=shop.provider.user.country,annexesImages';

    try {
        const response = await fetch(url);
        console.log("zzzzzz",response);
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des produits');
        }
        return await response.json();
    } catch (error) {
        console.error('Erreur:', error);
        throw error;
    }
};