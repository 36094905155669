import React from 'react';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../store/actions';

const Bouton = () => {

    const dispatch =useDispatch;

    const handleClean = ()=> {
        
        dispatch(clearCart());}

    
    return (
        
            <button onClick={handleClean}  >Clear All</button>
                            
    );
};

export default Bouton;