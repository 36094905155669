

const initialcardState = {
    items: [],
    subtotals: [], 
    quantity: 0,
};

const cartReducer = (state = initialcardState, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            // Vérifie si le produit est déjà dans le panier
            const productExists = state.items.some(item => item.id === action.payload.id);

            // Si le produit existe déjà, ne pas l'ajouter
            if (productExists) {
                return state;  // On ne fait rien si le produit est déjà dans le panier
            } else {
                return {
                    ...state,
                    items: [...state.items, action.payload], // Ajoute le produit si il n'existe pas
                    quantity: state.items.length + 1 // Mise à jour de la quantité
                };
            }

        case 'REMOVE_FROM_CART':
            return {
                ...state,
                items: state.items.filter(item => item.id !== action.payload), // Supprime le produit par id
                subtotals: state.subtotals.filter(subtotal => subtotal.productId !== action.payload), // Supprime le sous-total associé
                quantity: state.items.length - 1 // Mise à jour de la quantité après suppression
            };
        case 'CLEAR_CART':
            return {
                ...state,
                items: [],
                quantity: 0

            };
            case 'UPDATE_SUBTOTAL':
                // Vérifier si le produit existe déjà dans le tableau des sous-totaux
                const existingSubtotalIndex = state.subtotals.findIndex(
                  (item) => item.productId === action.payload.productId
                );
          
                // Si le produit a déjà un sous-total, on le met à jour
                if (existingSubtotalIndex !== -1) {
                  return {
                    ...state,
                    subtotals: state.subtotals.map((item, index) =>
                      index === existingSubtotalIndex
                        ? { ...item, subtotal: action.payload.subtotal }
                        : item
                    ),
                  };
                } else {
                  // Si le produit n'a pas encore de sous-total, on l'ajoute
                  return {
                    ...state,
                    subtotals: [...state.subtotals, action.payload],
                  };
                };

        default:
            return state;
    }
};

export default cartReducer;