import React from 'react';
import ReactDOM from 'react-dom';
// import './styleSheets/index.css';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';

import store from './store';
import { Provider } from 'react-redux';

import 'aos/dist/aos.css'; // Import des styles AOS (obligatoire)
import AOS from 'aos'; // Import de la bibliothèque AOS

AOS.init({
	duration: 1000, // Durée de l'animation (en ms)
	offset: 120,    // Décalage (lorsque l'élément est visible)
	easing: 'ease-in-out' // Type d'animation
  });
  
ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<App />
		</Provider>
	</I18nextProvider>,
	document.getElementById('root')
);