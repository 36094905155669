import React from 'react';

const Login = () => {
    return (
        <div ><section className="login-form d-flex align-items-center ">
            <div className="container">
                <div className="login-form-title text-center">
                    <figure className="login-page-logo">
                        <a className="navbar-brand mr-0 p-0" href="index.html"><img src="./assets/images/sencare-logo2.png" alt className="img-fluid" /></a>
                    </figure>
                    <h2>Welcome Back !</h2>
                </div>
                <div className="login-form-box">
                    <div className="login-card">
                        <form>
                            <div className="form-group">
                                <input className="input-field form-control" type="email" id="exampleInputEmail1" placeholder="Email" required />
                            </div>
                            <div className="form-group">
                                <input className="input-field form-control" type="password" id="exampleInputPassword1" placeholder="Password" required />
                            </div>
                            <button type="submit" className="btn btn-primary hover-effect">Login</button>
                            <div>
                                <label className="font-weight-normal mb-0" style={{ cursor: 'pointer' }}>
                                    <input className="checkbox" type="checkbox" name="userRememberMe" required />
                                    Remember me
                                </label>
                                <a href="#" className="forgot-password float-right">Lost Password?</a>
                            </div>
                        </form>
                    </div>
                    <div className="join-now-outer text-center">
                        <a href="signup.html">Join now, create your FREE account</a>
                    </div>
                </div>
            </div>
        </section></div>

    );
};

export default Login;