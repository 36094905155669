// src/components/CategoryList.js
import React from 'react';
import CategoryMenu from './CategoryMenu';

const CategoryList = ({ category }) => {
  return (
    <>
      {/* Affichage de la catégorie principale */}
      
      <CategoryMenu category={category} />
    </>
  );
};

export default CategoryList;
