import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SousCardcart from '../../components/SousCardcart';
import CategoryMenu from '../../components/CategoryMenu';
import CategoriesPage from '../../components/CategoriesPage';
import axios from 'axios';
import CategoryList from '../../components/CategoryList';
import { useDispatch, useSelector } from 'react-redux';
import ProductService from '../../../services/product';


const Appbar = () => {


    return (
        <>
        <div className="header-top header-top1 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-md-block d-sm-none">
                        <div className="header-left d-table-cell align-middle">
                            <div className="phone-icon d-inline-block"><i className="fa-solid fa-phone-volume" /></div><p className="free-consultation-text">For a free consultation:<a href="tel:+123456789" className="text-decoration-none">0800 123 45 67 890</a></p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="header-right float-md-right float-none">
                            <ul className="list-unstyled">
                                <li className="d-inline-block"><i className="fa-sharp fa-solid fa-envelope" /><a className="d-inline-block email-span text-decoration-none" href="mailto:info@sencare.com">info@sencare.com</a></li>
                               
                                <li className="d-inline-block ml-3">  <FontAwesomeIcon icon={faFacebook} size="1x" style={{ color: '#4267B2' }} /> </li>
                                <li className="d-inline-block ml-3"><FontAwesomeIcon icon={faWhatsapp} size="1x" style={{ color: '#25D366' }} /></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    <header className="header" style={{background:"white"}}>
        <div className="main-header" style={{ backgroundColor: "white" }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <Link className="navbar-brand mr-0" to={"/"}><img src="./assets/images/logo.png" alt className="img-fluid" /></Link>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon navbar-toggler-icon2" />
                        <span className="navbar-toggler-icon navbar-toggler-icon2" />
                        <span className="navbar-toggler-icon navbar-toggler-icon2" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown sancare-li-color active">
                                <a className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Accuiel</a>
                                <div className="dropdown-menu sancare-drop-down sencare-drop-down2">
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-decoration-none navbar-text-color index2-navlink" to={'/'}>A propos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-decoration-none navbar-text-color index2-navlink" to={'/'}>Contact</Link>
                            </li>
                            <li className="nav-item dropdown sancare-li-color">
                                <div className="dropdown-menu sancare-drop-down sencare-drop-down2">
                                    <ul className="list-unstyled">
                                        <li className="nav-item"> <a className="dropdown-item nav-link" href="comming-soon.html">Comming Soon</a></li>
                                        <li className="nav-item"> <a className="dropdown-item nav-link" href="404page.html">404 Page</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item list-unstyled  btn-talk nav-btn2"><a className="nav-link" href="contact.html">Connexion </a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>
    </>
    );
};

export default Appbar;