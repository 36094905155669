import React from 'react';
import { Link } from 'react-router-dom';
const ProductFeaturedCard = ({category}) => {
    return (
        <div className="swiper-slide">
        <div className="single-category-one height-230 " style={{width:225,margin:10}}>
            <div  className="thumbnail">
                <img src={category.images} alt="category" width="440" height="200"  />
            </div>
            <div className="inner-content-category">
                <p>{category.name}</p>
                <a href="javascript:;" className='btn btn-success'>Voir détails</a>
                
            </div>
        </div>
    </div>
    );
};

export default ProductFeaturedCard;