import React from 'react';
import "./pharmacie.css"
import { Link } from 'react-router-dom';
const Pharmacie = () => {
    return (
        <div>
            <div>
                
                <main style={{maxWidth:1400}}>
                <h2 className="offer-heading">Nos  <span className="support">services</span></h2>
                    
                        {/* <h2>Nos Services</h2> */}
                        <div className="service-cards offer-section offer-section1 about-offer-section"  data-aos="fade-up" style={{paddingTop:200}}>
                        
                            <div className="service-card">
                                <img src="assets/img/pharmacie.jpeg" alt="Pharmacie" />
                                <h4>Hopital 1</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                            <div className="service-card">
                                <img src="assets/img/Hopital.jpeg" alt="Hôpital" />
                                <h4>Hopital 2</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                            <div className="service-card">
                                <img src="assets/img/beaute_et_bien_etre.jpeg" alt="Beauté et Bien-être" />
                                <h4>Hopital 3</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                            <div className="service-card">
                                <img src="assets/img/batiment.jpeg" alt="Bâtiment et Construction" />
                                <h4>Hopital 4</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                        </div> <br /><br />
                        <div className="service-cards">
                            <div className="service-card">
                                <img src="assets/img/pharmacie.jpeg" alt="Pharmacie" />
                                <h4>Hopital</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                            <div className="service-card">
                                <img src="assets/img/Hopital.jpeg" alt="Hôpital" />
                                <h4>Hopital</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                            <div className="service-card">
                                <img src="assets/img/beaute_et_bien_etre.jpeg" alt="Beauté et Bien-être" />
                                <h4>Hopital</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                            <div className="service-card">
                                <img src="assets/img/batiment.jpeg" alt="Bâtiment et Construction" />
                                <h4>Hopital</h4>
                                <p>Localisation : Douala</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/abonnements"}>Voir détails</Link>
                            </div>
                        </div>
                        <br /><br />
                        <br /><br />
                        <br /><br />
                        <br /><br />
                    
                </main>
                <footer>
                    <p>© 2024 Votre Entreprise. Tous droits réservés.</p>
                    <ul>
                        <li><a href="#">Mentions légales</a></li>
                        <li><a href="#">Politique de confidentialité</a></li>
                        <li><a href="#">Nous contacter</a></li>
                    </ul>
                </footer>
            </div>


        </div>
    );
};

export default Pharmacie;