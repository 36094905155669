import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector  } from 'react-redux';
import { addToCart, addToWishlist,incrementQuantity } from '../../store/actions';
import SousCard from './SousCard';
const ProductPopularCArd = ({ product }) => {

    const quantity = useSelector(state => state.__ct.quantity);
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();

    const handleAddToCart = () => {
        dispatch(addToCart(product)); // Envoie l'action d'ajout au panier
    }
    const addQantity = () => {
        dispatch(incrementQuantity());
      };
    const handleAddToWishlist = () => {
        dispatch(addToWishlist(product)); // Envoie l'action d'ajout au panier
    }
    const toggleVisibility = () => {
        setIsVisible(!isVisible); // On inverse la visibilité à chaque clic
    };


    return (
        <>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" style={{}}>
                <div className="single-shopping-card-one deals-of-day">

                    <div className="image-and-action-area-wrapper" style={{ width: 200, height: 225 }}>
                        <div className="thumbnail-preview">
                            <Link to={`/8/${product.id}`} key={product.real_id}>
                                <img src={product.image} alt="grocery" width="500" height="200" />
                            </Link>
                        </div>
                        <div className="action-share-option">
                            <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist" onClick={handleAddToWishlist}>
                                <i className="fa-light fa-heart" />
                            </div>
                            <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                            </div>
                            <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" onClick={toggleVisibility} />

                            </div>
                        </div>
                    </div>
                    <div className="body-content">
                        <div className="start-area-rating">
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                            <i className="fa-solid fa-star" />
                        </div>
                        <Link to={`/8/${product.id}`} key={product.real_id}>
                            <h4 className="title">{product.name}</h4>
                        </Link>
                        <span className="availability">{product.weight}g Pack</span>
                        <div className="price-area">
                            <span className="current">${product.price}</span>
                            <div className="previous">${product.price}</div>
                        </div>
                        <div className="cart-counter-action">
                            <div className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text" onClick={()=>{handleAddToCart(); addQantity()}}  style={{cursor: 'pointer'}}>
                                    Add To Cart
                                </div>
                                <div className="arrow-icon">
                                    <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                    <i className="fa-regular fa-cart-shopping" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isVisible && (
                <div style={{ position: 'absolute', zIndex: 10 }}>
                    <SousCard product={product} />
                </div>
            )}


        </>

    );
};

export default ProductPopularCArd;