import React from 'react';
import "./home.css"
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const goToPage = () => {
        navigate('/abonnements');
    };
    const goToPage2 = () => {
        navigate('/abonnements2');
    };
    const goToPage3 = () => {
        navigate('/abonnements3');
    };
    const goToPage4 = () => {
        navigate('/personnalise');
    };
  

    return (

        <div className='fontF'>

            <div className="home-header-section home-header-section1 ">

                {/* BANNER-SECTION */}
                <div className="home-banner-section overflow-hidden home-banner-section2 home-banner-section1">
                    <div className="banner-container-box">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-4 text-md-left text-center d-flex align-items-center">
                                    <div className="home-banner-text " data-aos="fade-up">
                                        <h1>Payez les factures de vos <span className="banner-deserve"> proches</span></h1>
                                        <p className="banner-paragraph">
                                            Que ce soit pour vos besoins de santé, d'énergie, de divertissement ou d'éducation, nous sommes là pour vous accompagner avec des solutions rapides et fiables.
                                        </p>

                                        <div className="banner-btn discover-btn-banner">
                                            <Link to="/abonnements" className="text-decoration-none">NOS OFFRES</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="banner-img-section position-relative">
                                        <figure className="banner-img2-figure">
                                            <img src="./assets/images/index-banner-right-img.png" alt />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* offer-section */}
            <div className="offer-section offer-section1" data-aos="fade-up" style={{ paddingBottom: 100 }}>
                <h2>Nos packs de prise en   <span className="support"> charge</span></h2>





                <div className="container" >
                    <div className="row g-4"> {/* Utilisation de g-4 pour l'espacement entre les éléments */}

                        <div className="col-lg-3 col-md-4 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={goToPage}>
                            <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
                                <div className="offer-section-inner">
                                    <figure>
                                        <img src="./assets/images/offer-section-img2.png" alt="" className="img-fluid" />
                                    </figure>
                                </div>
                                <div>
                                    <p c style={{ paddingBottom: 5, fontWeight: "bold" }}>Pack santé</p>
                                </div>
                                <p>Prise en charge complète des besoins médicaux pour garantir la santé et le bien-être.</p>
                                <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-success">Voir plus</button>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={goToPage2}>
                            <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
                                <div className="offer-section-inner">
                                    <figure>
                                        <img src="./assets/images/offer-section-img3.png" alt="" className="img-fluid" />
                                    </figure>
                                </div>
                                <div style={{ paddingBottom: 5 }}>
                                    <p style={{ fontWeight: "bold" }}>Pack gestion du domicile </p>

                                </div>
                                <p>Offre complète d'assistance pour la gestion et l'organisation des tâches à domicile.</p>
                                <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <Link to={"/abonnements2"} type="button" className="btn btn-success">Voir plus</Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={goToPage3}>
                            <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
                                <div className="offer-section-inner">
                                    <figure>
                                        <img src="./assets/images/offer-section-img1.png" alt="" className="img-fluid" />
                                    </figure>
                                </div>
                                <div className='d-flex justify-content-center align-items-center' style={{ paddingBottom: 5 }}>
                                    <p style={{ fontWeight: "bold" }}>Pack besoin informatique</p>

                                </div>
                                <p>Prise en charge des besoins administratifs et informatiques</p>
                                <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <Link to={"/abonnements3"}  className="btn btn-success">Voir plus</Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4 col-sm-6 mb-3" style={{ cursor: "pointer" }}  onClick={goToPage4} >
                            <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
                                <div className="offer-section-inner">
                                    <figure>
                                        <img src="./assets/images/offer-icon1.png " alt="" className="img-fluid" />
                                    </figure>
                                </div>
                                <div>
                                    <p style={{ paddingBottom: 5, fontWeight: "bold" }}>Pack personnalisé</p>
                                </div>
                                <p>Accompagnement complet pour la gestion des besoins alimentaires, avec des solutions adaptées et personnalisées.</p>
                                <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-success">Voir plus</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


























            </div>
            {/* Popup-Section */}
            <div className="offer-section offer-section1" data-aos="fade-up" style={{ paddingTop: 200 }}>  
            <div className=" container-fluid  containerc">
                <h2 style={{marginBottom:100, paddingTop: 100}}>Comment ça marche</h2>
                <div className="steps">
                    <div className="step">
                        <div className="step-number">1</div>
                        <div className="step-icon"><i className="fas fa-box" /></div>
                        <div className="step-title">Sélectionnez un pack adapté</div>
                        <div className="step-description">Parcourez les différents packs disponibles ou créez un pack
                            personnalisé pour répondre <br/>  à vos besoins <br/>spécifiques.</div>
                    </div>
                    <div className="step">
                        <div className="step-number">2</div>
                        <div className="step-icon"><i className="fas fa-tags" /></div>
                        <div className="step-title">Choisissez l’offre qui vous convient</div>
                        <div className="step-description">Explorez les offres disponibles et sélectionnez celle(s) qui
                            répond(ent) le mieux aux besoins <br/> de vos proches.</div>
                    </div>
                    <div className="step">
                        <div className="step-number">3</div>
                        <div className="step-icon"><i className="fas fa-user-plus" /></div>
                        <div className="step-title">Créez votre compte Protecteur</div>
                        <div className="step-description">Inscrivez-vous en tant que Protecteur, puis ajoutez votre ayant droit
                            en lui attribuant une carte <br/>de bénéficiaire.</div>
                    </div>
                    <div className="step" >
                        <div className="step-number">4</div>
                        <div className="step-icon"><i className="fas fa-credit-card" /></div>
                        <div className="step-title" style={{textAlign:"center"}}>Optez pour un mode de paiement</div>
                        <div className="step-description payment-options" style={{marginBottom:70,textAlign:"center"}}>
                            <strong>Paiement mensuel :</strong> Flexible et sans engagement.<br />
                            <strong>Souscription annuelle :</strong> Profitez d’une formule économique sur une année
                            complète.<br />
                            <strong>Parrainage :</strong> Invitez d’autres Protecteurs à rejoindre la plateforme pour soutenir
                            leurs proches. Activez ainsi une offre gratuite <br/> pour votre famille.
                        </div>
                    </div>
                </div>
            </div>
            </div>

            {/* Popup-Section */}

            <div className="offer-section popup-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <h2 data-aos="fade-up">Rejoignez Notre Plateforme et Faites Connaître<span className="support"> Vos Services</span></h2>
                            <p>Présentation des entreprises offrant une gamme variée de services destinés à répondre aux besoins spécifiques des clients, allant des solutions en télécommunication, santé, énergie, éducation, jusqu'aux services bancaires et autres prestations indispensables au quotidien.</p>
                            <div className="popup-btn">
                                <a href="about.html">S'enregistrer</a>
                            </div>
                            <h4>Need clinical advice?</h4>
                            <h4 className="popup-section-number">0800 123 45 678</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="video-section-box">
                                <a className="popup-vimeo" href="https://previews.customer.envatousercontent.com/bfa4eae3-5be3-457e-9c5f-31e840d016e5/watermarked_preview/watermarked_preview.mp4">
                                    <figure className="mb-0">
                                        <img src="./assets/images/popup-video-img.png" style={{ cursor: 'pointer' }} alt="" className="img-fluid video-img" />
                                    </figure>
                                </a>
                                <figure className="position-absolute popup-top-figure">
                                    <img src="./assets/images/video-section-top-img.png" alt="" className="img-fluid" />
                                </figure>
                                <figure className="position-absolute popup-bottom-figure">
                                    <img src="./assets/images/video-section-bottom-img.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Counter-Section */}

            <div className="counter-section2">
                <div className="container">
                    <div className="counter-inner-row">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box vl-border">
                                    <h5><span className="counter">45</span></h5>
                                    <p>Donations Every Year</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box vl-right-border">
                                    <h5><span className="counter">690</span></h5>
                                    <p>Great Local Volunteers</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box">
                                    <h5><span className="counter">437</span></h5>
                                    <p>Face to Face Visits</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box border-right-box">
                                    <h5><span className="counter">170</span></h5>
                                    <p>Organized Local Events</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service-Offer-Section */}
            <div style={{ height: 10 }}></div>
            {/*Happy-Clients-Section  */}
            <section className="temoignage happy-clients-section happy-clients-section2  " data-aos="fade-up">
                <div className="offer-section offer-section1 " style={{ marginTop: 100 }} data-aos="fade-up">
                    <h2 >Temoignages <span className="support">.</span></h2>




                    <div className="row d-flex flex-wrap justify-content-between " style={{ paddinggBottom: 100, paddingTop: 50, paddingLeft: 15, paddingRight: 15, marginBottom: 100 }}>
                        {/* Element 1 */}
                        <div className="col-md-3">
                            <div className="client-card">
                                <figure>
                                    <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
                                </figure>
                                <p className="carousel-text">J'ai trouvé tout ce dont j'avais besoin : appli mobile, assistance admin, conseils RH. Très satisfait !</p>
                                <div className="about-border"></div>
                                <p className="carousel-title">Laura P</p>
                                <p className="carousel-end-text">Senior Advisor</p>
                                <figure className="carousel-bottom-fig">
                                    <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>

                        {/* Element 2 */}
                        <div className="col-md-3">
                            <div className="client-card">
                                <figure>
                                    <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
                                </figure>
                                <p className="carousel-text">Excellente expérience ! L'application mobile est intuitive et le support client est très réactif.</p>
                                <div className="about-border"></div>
                                <p className="carousel-title">John D</p>
                                <p className="carousel-end-text">Project Manager</p>
                                <figure className="carousel-bottom-fig">
                                    <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>

                        {/* Element 3 */}
                        <div className="col-md-3">
                            <div className="client-card">
                                <figure>
                                    <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
                                </figure>
                                <p className="carousel-text">L'application mobile est facile à utiliser et l'interface est très agréable. Service client très pro !</p>
                                <div className="about-border"></div>
                                <p className="carousel-title">Emma L</p>
                                <p className="carousel-end-text">Product Designer</p>
                                <figure className="carousel-bottom-fig">
                                    <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>

                        {/* Element 4 */}
                        <div className="col-md-3">
                            <div className="client-card">
                                <figure>
                                    <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
                                </figure>
                                <p className="carousel-text">Une équipe réactive et professionnelle. L'outil est simple à utiliser et très fonctionnel.</p>
                                <div className="about-border"></div>
                                <p className="carousel-title">Paul R</p>
                                <p className="carousel-end-text">UX Researcher</p>
                                <figure className="carousel-bottom-fig">
                                    <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>
                    </div>













                </div>
            </section>

            {/* Footer */}
        </div>

    );
};

export default Home;