import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart, incrementQuantity } from '../../store/actions';
import SousCard from './SousCard';

const CardShop = ({ product }) => {
    const [Q, setQ] = useState(1);
    const [isVisible, setIsVisible] = useState(false);
    
    const dispatch = useDispatch();

    const handleAddToCart = () => {
        dispatch(addToCart(product)); // Envoie l'action d'ajout au panier
        dispatch(incrementQuantity());
    };
    const toggleVisibility = () => {
        setIsVisible(!isVisible); // On inverse la visibilité à chaque clic
    };

    const addQ = () => {
        setQ(prevQ => {
            if (prevQ <= product.quantity) {
                return prevQ + 1;
            }
            return prevQ;
        });
    }
    const reduceQ = () => {
        setQ(prevQ => {
            if (prevQ > 0) {
                return prevQ - 1;
            }
            return prevQ;
        });
    }
    




    return (
        <div className="col-lg-20 col-lg-4 col-md-6 col-sm-6 col-12" >

            <div className="single-shopping-card-one" style={{ height: 420 }}>
                {/* iamge and sction area start */}
                <div className="image-and-action-area-wrapper">
                    <div to="/7" className="thumbnail-preview">
                        <div className="badge">
                            <span>25% <br />
                                Off
                            </span>
                            <i className="fa-solid fa-bookmark" />
                        </div>
                        <Link to={`/8/${product.id}`} key={product.real_id}>
                            <div >
                                <img src={product.image} alt="grocery" style={{
                                    height: 200, maxWidth: '100%',
                                    maxHeight: '100%',
                                    display: 'block',
                                    margin: 'auto'
                                }} />
                            </div>
                        </Link>
                    </div>
                    <div className="action-share-option">
                        <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                            <i className="fa-light fa-heart" />
                        </div>
                        <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <i className="fa-solid fa-arrows-retweet" />
                        </div>
                        <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                            <i className="fa-regular fa-eye" onClick={toggleVisibility} />
                        </div>
                    </div>
                </div>
                {/* iamge and sction area start */}
                <div className="body-content">
                    <Link to={`/8/${product.id}`} key={product.real_id}>
                        <h4 className="title">{product.name}</h4>
                    </Link>
                    <span className="availability">500g Pack</span>
                    <div className="price-area">
                        <span className="current">${product.price}</span>
                        <div className="previous">${product.price}</div>
                    </div>
                    <div className="cart-counter-action">
                        <div className="quantity-edit">
                            <input type="text" className="input" value={Q} />
                            <div className="button-wrapper-action">
                                <button className="button" onClick={reduceQ}><i className="fa-regular fa-chevron-down" /></button>
                                <button className="button plus" onClick={addQ}>+<i className="fa-regular fa-chevron-up" /></button>
                            </div>
                        </div>
                        <div className="rts-btn btn-primary radious-sm with-icon">
                            <div className="btn-text" onClick={handleAddToCart} style={{ cursor: "pointer" }} >
                                Add To Cart
                            </div>
                            <div className="arrow-icon">
                                <i className="fa-regular fa-cart-shopping" />
                            </div>
                            <div className="arrow-icon">
                                <i className="fa-regular fa-cart-shopping" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isVisible && (
                <div style={{ position: 'absolute', zIndex: 10 }}>
                    <SousCard product={product} />
                </div>
            )}
        </div>

    );
};

export default CardShop;