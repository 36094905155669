import React from 'react';
import "./footer.css";
const Footer = () => {
    return (
     <div className='fter'>
      <div className="footer-section footer-img-section footer-index-img position-relative ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <figure className="sencare-logo-footer">
                                <a className="navbar-brand mr-0" href="index.html"><img src=""  alt className="img-fluid" /></a>
                            </figure>
                            <p className="footer-text">Copyright 2020 DesigningMedia. All Rights reserved</p>
                            <div className="social-icons text-center">
                                <ul className="list-unstyled">
                                    <li><a href className="text-decoration-none"><i className="fa-brands fa-twitter social-networks" /></a></li>
                                    <li><a href className="text-decoration-none"><i className="fa-brands fa-facebook-f social-networks" /></a></li>
                                    <li><a href className="text-decoration-none"><i className="fa-brands fa-pinterest-p social-networks" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-2 col-sm-12 d-lg-block d-none">
                            <ul className="list-unstyled footer-list-ul">
                                <li className="list-item footer-margin-left">
                                    <h4 className="footer-link  footer-heading">
                                        Useful Links
                                    </h4>
                                </li>
                                <li>
                                    <a href="about.html" className="text-decoration-none footer-link-p">
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a href="comming-soon.html" className="text-decoration-none footer-link-p">
                                        Comming Soon
                                    </a>
                                </li>
                                <li>
                                    <a href="404page.html" className="text-decoration-none footer-link-p">
                                        404 page
                                    </a>
                                </li>
                                <li>
                                    <a href="about.html" className="text-decoration-none footer-link-p">
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a href="faq.html" className="text-decoration-none footer-link-p">
                                        Faq Page
                                    </a>
                                </li>
                                <li>
                                    <a href="contact.html" className="text-decoration-none footer-link-p">
                                        Conatct us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <ul className="list-unstyled footer-list-ul contact-info-heading">
                                <li className="list-item footer-margin-left">
                                    <h4 className="footer-link footer-heading">
                                        Contact Info
                                    </h4>
                                </li>
                                <li className="footer-margin-bottom">
                                    <a href="about.html" className="text-decoration-none">
                                        Address:
                                    </a>
                                </li>
                                <li className="pr-2 mb-3">
                                    <a className="text-decoration-none">
                                        121 King Street Melbourne,
                                        3000, Australia
                                    </a>
                                </li>
                                <li className="footer-margin-bottom">
                                    <a className="text-decoration-none">
                                        Email:
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="mailto:info@sencare.com" className="text-decoration-none">
                                        info@sencare.com
                                    </a>
                                </li>
                                <li className="footer-margin-bottom">
                                    <a href="Find-Dealer.html" className="text-decoration-none">
                                        Phone:
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+123456789" className="text-decoration-none">
                                        +1 23 45 6789
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <ul className="list-unstyled">
                                <li className="list-item">
                                    <h4 className="footer-link  footer-heading instagram-heading">
                                        Instagram
                                    </h4>
                                </li>
                            </ul>
                            <div className="footer-images">
                                <div className="images-inner-box">
                                    <ul className="list-unstyled mb-1">
                                        <li className="list-item d-inline-block">
                                            <figure className="mb-0"><img src="./assets/images/footer-img1.png" alt="Snow" className="img-fluid footer-imgs" /></figure>
                                        </li>
                                        <li className="list-item d-inline-block">
                                            <figure className="mb-0"><img src="./assets/images/footer-img2.png" alt="Snow" className="img-fluid footer-imgs" /></figure>
                                        </li>
                                        <li className="list-item d-inline-block">
                                            <figure className="mb-0"><img src="./assets/images/footer-img3.png" alt="Snow" className="img-fluid footer-imgs" /></figure>
                                        </li>
                                    </ul>
                                </div>
                                <div className="images-inner-box">
                                    <ul className="list-unstyled">
                                        <li className="list-item d-inline-block">
                                            <figure className="mb-0"><img src="./assets/images/footer-img4.png" alt="Snow" className="img-fluid footer-imgs" /></figure>
                                        </li>
                                        <li className="list-item d-inline-block">
                                            <figure className="mb-0"><img src="./assets/images/footer-img5.png" alt="Snow" className="img-fluid footer-imgs" /></figure>
                                        </li>
                                        <li className="list-item d-inline-block">
                                            <figure className="mb-0"><img src="./assets/images/footer-img6.png" alt="Snow" className="img-fluid footer-imgs" /></figure>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* BLOG SECTION POPUP */}
            <div id="blog-model-1" className="modal fade blog-model-con" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fa-solid fa-x" /></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="blog-box-item mb-0">
                                <div className="blog-img">
                                    <figure className="mb-0">
                                        <img src="./assets/images/popup-blog-img1.jpg" alt="blog-img" className="img-fluid" />
                                    </figure>
                                </div>
                                <div className="blog-content pl-0 pr-0">
                                    <div className="blog-auteher-title">
                                        <span>By Elina Parker</span>
                                        <span className="float-lg-right">Sep 01, 2022</span>
                                    </div>
                                    <div className="span_wrapper">
                                        <i className="fa-solid fa-calendar-days" aria-hidden="true" /><span> 25 Oct, 2020  |  by admin</span>
                                    </div>
                                    <p className="blog_p mb-0">Magni dolores eos qui ratione voluptatem tempora incidunt sequi</p>
                                    <p className="pp">
                                        Quis nostrum exercitationem ullam corporis suscit labor iosam, nisi ut aliquid ex ea commodi conse aute irure dolor
                                        in reprehenderit in voluptateesse occaecat cuida at non proident, sunt in culpa qui officia deserun.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempor eros a tellus auctor, nec suscipit nunc dignissim. Ut suscipit gravida augue sed elementum. Sed sed luctus nisl. Donec scelerisque nisi in sodales mattis. Vestibulum suscipit odio ac enim blandit sollicitudin.
                                    </p>
                                    <h2>Get In Touch With Us</h2>
                                    <div className="carousel-card-form position-relative">
                                        <form method="post" action="contact-form.php">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="border-bottom-outer position-relative">
                                                        <input className="form-input-fields" type="text" name="fname" placeholder="First Name" />
                                                    </div>
                                                    <input className="form-input-fields" type="text" name="email" placeholder="Email" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <input className="form-input-fields" type="text" name="lname" placeholder="Last Name" />
                                                    <input className="form-input-fields" type="text" name="phone" placeholder="Phone" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <input type="text" name="message" className="message-field" placeholder="Message" />
                                                    <div className="popup-btn">
                                                        <button type="submit" className="btn btn-primary">SEND MESSAGE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="blog-model-2" className="modal fade blog-model-con" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fa-solid fa-x" /></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="blog-box-item mb-0">
                                <div className="blog-img">
                                    <figure className="mb-0">
                                        <img src="./assets/images/popup-blog-img2.jpg" alt="blog-img" className="img-fluid" />
                                    </figure>
                                </div>
                                <div className="blog-content pl-0 pr-0">
                                    <div className="blog-auteher-title">
                                        <span>By Elina Parker</span>
                                        <span className="float-lg-right">Sep 01, 2022</span>
                                    </div>
                                    <div className="span_wrapper">
                                        <i className="fa-solid fa-calendar-days" aria-hidden="true" /><span> 25 Oct, 2020  |  by admin</span>
                                    </div>
                                    <p className="blog_p mb-0">Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis</p>
                                    <p className="pp">
                                        Quis nostrum exercitationem ullam corporis suscit labor iosam, nisi ut aliquid ex ea commodi conse aute irure dolor
                                        in reprehenderit in voluptateesse occaecat cuida at non proident, sunt in culpa qui officia deserun.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempor eros a tellus auctor, nec suscipit nunc dignissim. Ut suscipit gravida augue sed elementum. Sed sed luctus nisl. Donec scelerisque nisi in sodales mattis. Vestibulum suscipit odio ac enim blandit sollicitudin.
                                    </p>
                                    <h2>Get In Touch With Us</h2>
                                    <div className="carousel-card-form position-relative">
                                        <form method="post" action="contact-form.php">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="border-bottom-outer position-relative">
                                                        <input className="form-input-fields" type="text" name="fname" placeholder="First Name" />
                                                    </div>
                                                    <input className="form-input-fields" type="text" name="email" placeholder="Email" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <input className="form-input-fields" type="text" name="lname" placeholder="Last Name" />
                                                    <input className="form-input-fields" type="text" name="phone" placeholder="Phone" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <input type="text" name="message" className="message-field" placeholder="Message" />
                                                    <div className="popup-btn">
                                                        <button type="submit" className="btn btn-primary">SEND MESSAGE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="blog-model-3" className="modal fade blog-model-con" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fa-solid fa-x" /></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="blog-box-item mb-0">
                                <div className="blog-img">
                                    <figure className="mb-0">
                                        <img src="./assets/images/popup-blog-img3.jpg" alt="blog-img" className="img-fluid" />
                                    </figure>
                                </div>
                                <div className="blog-content pl-0 pr-0">
                                    <div className="blog-auteher-title">
                                        <span>By Elina Parker</span>
                                        <span className="float-lg-right">Sep 01, 2022</span>
                                    </div>
                                    <div className="span_wrapper">
                                        <i className="fa-solid fa-calendar-days" aria-hidden="true" /><span> 25 Oct, 2020  |  by admin</span>
                                    </div>
                                    <p className="blog_p mb-0">Duis aute irure dolor in reprehenderit in voluptate velit esse</p>
                                    <p className="pp">
                                        Quis nostrum exercitationem ullam corporis suscit labor iosam, nisi ut aliquid ex ea commodi conse aute irure dolor
                                        in reprehenderit in voluptateesse occaecat cuida at non proident, sunt in culpa qui officia deserun.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempor eros a tellus auctor, nec suscipit nunc dignissim. Ut suscipit gravida augue sed elementum. Sed sed luctus nisl. Donec scelerisque nisi in sodales mattis. Vestibulum suscipit odio ac enim blandit sollicitudin.
                                    </p>
                                    <h2>Get In Touch With Us</h2>
                                    <div className="carousel-card-form position-relative">
                                        <form method="post" action="contact-form.php">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="border-bottom-outer position-relative">
                                                        <input className="form-input-fields" type="text" name="fname" placeholder="First Name" />
                                                    </div>
                                                    <input className="form-input-fields" type="text" name="email" placeholder="Email" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <input className="form-input-fields" type="text" name="lname" placeholder="Last Name" />
                                                    <input className="form-input-fields" type="text" name="phone" placeholder="Phone" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <input type="text" name="message" className="message-field" placeholder="Message" />
                                                    <div className="popup-btn">
                                                        <button type="submit" className="btn btn-primary">SEND MESSAGE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        
     </div>
    );
};

export default Footer;